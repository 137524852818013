import React,{Fragment} from 'react';
import Data from '../../data/social-media.json';

const SocialMedia=()=>{
    return(
        <Fragment>
            <ul>
                {Data.map(social=>{
                    return(
                        <li key={social.id}>
                            <a href={social.socialLink} target="_blank" rel="noopener noreferrer">{social.socialTitle}</a>
                        </li>
                    );
                })}
            </ul>
        </Fragment>
    );
}
export default SocialMedia;
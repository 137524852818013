import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "../helper/SectionTitle";
import PortfolioGrid from "./PortfolioGrid";

const Portfolio = ({ DarkVersion }) => {
    return(
		<section id="portfolio" className={`portfolio-area portfolio-one section-padding clearfix ${DarkVersion ? 'dark-version bg-color-3' : 'light-bg'}`}>
			<Container>
				<Row>
					<Col>
						<SectionTitle title="MY PORTFOLIO"></SectionTitle>
					</Col>
				</Row>
				<Row>
					<Col>
						<PortfolioGrid DarkVersion={DarkVersion}></PortfolioGrid>
					</Col>
				</Row>
			</Container>
		</section>
    );
}
export default Portfolio;
import React,{Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import SectionTitle from '../helper/SectionTitle';
import Post from './Post';

const BlogPost = ({ DarkVersion }) => {
    return(
        <Fragment>
            <section id="blog" className={`blog-area section-padding ${DarkVersion ? 'dark-version dark-bg' : 'bg-color-1'}`}>
                <Container>
                    <Row>
                        <Col>
                            <SectionTitle title="My Blog"></SectionTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Post DarkVersion={DarkVersion}></Post>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}
export default BlogPost;
import React,{Fragment} from 'react';
import Data from '../../data/myCV.json';

const MyCV = () => {
    return(
        <Fragment>
            {
                Data.map((MyData)=>{
                    return(
                        <div key={MyData.id} className="left mr-40">
                            <h3 className="font-22 capitalize">{MyData.Title}</h3>
                            <p className="font-16 line-height-28">
                                {MyData.Desc}
                            </p>
                            <div className="about-button mt-30">
                                <a href={require('../../assets/img/' + MyData.BtnLink).default} className="btn btn-dark" download>{MyData.BtnText}</a>
                            </div>
                        </div>
                    );
                })
            }
        </Fragment>
    );
}
export default MyCV;
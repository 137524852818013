import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import NavScrollTop from './components/NavScrollTop';
import { HomeLight, HomeDark, SinglePostLight, SinglePostDark } from './pages';


function App() {
  return (
    <Router basepath="/rakib.dottheme.com">
        <NavScrollTop>
          <Switch>
          <Route path={"/"} exact component={HomeDark}></Route>
            <Route path={"/blog-post/:id"} component={SinglePostDark}></Route>
            <Route path={"/light-version"} exact component={HomeLight}></Route>
            <Route path={"/blog-post-light/:id"} component={SinglePostLight}></Route>
          </Switch>
        </NavScrollTop>
    </Router>
  );
}

export default App;

import React, { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from '../helper/SectionTitle';
import Post from './Post';
import SinglePost from './SinglePost';

const BlogDetails = ({ PostData, DarkVersion }) => {
    
    return (
        <Fragment>
            <div className={`blog-details-area section-padding ${DarkVersion ? 'dark-version bg-color-3' : 'bg-light'}`}>
                <Container>
                    <Row>
                        <Col sm={10} className="col-text-center">
                            <SinglePost PostDataPass={PostData} ></SinglePost>
                        </Col>
                    </Row>
                    <Row className="related-post">
                        <Col xs={12}>
                            <SectionTitle title="Related Post"></SectionTitle>
                        </Col>
                        <Post DarkVersion={DarkVersion}></Post>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}
export default BlogDetails;
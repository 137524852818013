import React,{Fragment} from 'react';
import InnerHeader from '../components/header/InnerHeader';
import Footer from '../components/footer/Footer';
import ScrollToTop from '../components/ScrollToTop';

const InnerLayout = ({ DarkVersion, children, GoBackLink })=>{
    return(
        <Fragment>
            <InnerHeader DarkVersion={DarkVersion} GoBackLink={GoBackLink}></InnerHeader>
            {children}
            <Footer DarkVersion={DarkVersion}></Footer>
            <ScrollToTop></ScrollToTop>
        </Fragment>
    );
}
export default InnerLayout;
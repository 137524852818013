import React,{Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import SectionTitle from '../helper/SectionTitle';
import Review from './Review';

const Testimonail = ({ DarkVersion })=>{
    return(
        <Fragment>
			<section className={`testimonial-area section-padding ${DarkVersion ? 'dark-version bg-color-3' : 'light-bg'}`}>
			<Container>
				<Row>
					<Col>
						<SectionTitle title="Testimonial"></SectionTitle>
					</Col>
				</Row>
				<Row className="text-center">
					<Col sm={10} md={8} className="col-text-center">
						<div id="one-item"  className="one-item">
							<Review></Review>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
        </Fragment>
    );
}
export default Testimonail;
import React,{Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import SectionTitle from '../helper/SectionTitle';
import Timeline from './Timeline';

const Experience = ({ DarkVersion })=>{
    return(
        <Fragment>
            <section className={`experience-area section-padding ${DarkVersion ? 'dark-version dark-bg' : 'bg-color-1'}`}>
                <Container>
                    <Row>
                        <Col>
                            <SectionTitle title="MY EXPERIENCE"></SectionTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex flex-wrap">
                            <Timeline></Timeline>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}
export default Experience;
import React,{Fragment} from 'react';
import { Link } from "react-scroll";
import Data from '../../data/nav.json';

const NavItem =()=>{
    return(
        <Fragment>
            <ul>
                {Data.map(item=>{
                    return(
                        <li key={item.id}>
                            <Link
                                activeClass="active"
                                to={item.navlink}
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                {item.navTitle}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </Fragment>
    );
}
export default NavItem;


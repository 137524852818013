import React, { Fragment } from 'react';
// import Swiper core and required modules
import SwiperCore, { Pagination } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import Data from '../../data/review.json';

// install Swiper modules
SwiperCore.use([Pagination]);

const Review=()=>{

    const mySwiper = {
        // Optional parameters
        loop: true,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        slidesPerView: 1,

        breakpoints: {
            // when window width is <= 499px
            499: {
                slidesPerView: 1,
                spaceBetweenSlides: 30
            },
            // when window width is <= 999px
            999: {
                slidesPerView: 1,
                spaceBetweenSlides: 40
            }
        }
    };


    return(
        <Fragment>
            <Swiper {...mySwiper}>
            {
                Data.map(ReviewData=>{
                    return(
                        <SwiperSlide key={ReviewData.id} className="single-slide">
                            <div className="slide-text pb-60 plr-10">
                                <div className="img mb-20">
                                    <img src={require('../../assets/img/testimonial/' + ReviewData.Picture).default} alt={ReviewData.Name} loading="lazy" width="98" height="98"/>
                                </div>
                                <div className="content">
                                    <h4 className="montserrat weight-bold">{ReviewData.Name}</h4>
                                    <h6 className="montserrat weight-bold mb-10">{ReviewData.Role}</h6>
                                    <p className="line-height-28 mx-auto">{ReviewData.Message}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    );
                })
            }
            </Swiper>
            <div className="swiper-pagination"></div>
        </Fragment>
    );
}
export default Review;
import React, { Fragment } from 'react';
import { Col } from 'react-bootstrap';
import * as Icons from "react-icons/md";
import Data from '../../data/service.json';


const Service = () => {
    return (
        <Fragment>
            {
                Data.map(ServiceData => {
                    const { [ServiceData.icon]: Icon } = Icons
                    return (
                        <Col key={ServiceData.id} sm={6} md={3} className="mb-md-5 mobile-mb-30">
                            <div className="single-service pt-50 pb-60 plr-20">
                                <Icon className="service-icon"></Icon>
                                <h5 className="text-capitalize montserrat weight-medium no-margin">{ServiceData.Title}</h5>
                                <hr className="mtb-15" />
                                <p>{ServiceData.Desc}</p>
                            </div>
                        </Col>
                    );
                })
            }
        </Fragment>
    );
}
export default Service;
import React, { Fragment, useEffect } from 'react';

const SkillBar = ({ Percentage, Title }) => {

    useEffect(() => {
        function loading() {
            document.querySelectorAll(".bar").forEach(function (current) {
                let startWidth = 0;
                const endWidth = current.dataset.size;

                const interval = setInterval(frame, 20);

                function frame() {
                    if (startWidth >= endWidth) {
                        clearInterval(interval);
                    } else {
                        startWidth++;
                        current.style.width = `${endWidth}%`;
                        current.firstElementChild.innerText = `${startWidth}%`;
                    }
                }
            });
        }
        loading();
    });

    return(
        <Fragment>
            
            <div className="progress-bar">
                <span className="progress-title">{Title}</span>
                <div className="bar" data-size={Percentage}>
                    <span className="perc"></span>
                </div>
            </div>
        </Fragment>
    );
}
export default SkillBar;
import React, { Fragment, useState, useRef, useEffect } from 'react';
import Logo from '../helper/Logo';
import LightLogo from '../../assets/img/light-logo.png';
import DarkLogo from '../../assets/img/dark-logo.png';
import InnerNavItem from './InnerNavItem';
import { MdMenu, MdCancel } from 'react-icons/md';
import { Container, Row, Col } from 'react-bootstrap';

const HeaderTwo = ({ DarkVersion, GoBackLink}) => {

	// Expand Menu 'Slide From Right'
	const [isActive, setActive] = useState(false);
	const menuToggle = () => {
		setActive(!isActive);
	}
	// Close Menu 'Close Menu on click document without containes'
	const isRef = useRef(null);
	const clickOutSide = (event) => {
		if (isRef.current && !isRef.current.contains(event.target)) {
			setActive(false);
		}
	}
	useEffect(() => {
		document.addEventListener('click', clickOutSide, true);
		return () => {
			document.removeEventListener('click', clickOutSide, true);
		}
	}, []);

	// Sticky Active Class add and remove on Scroll
	const [scrolled, setScrolled] = React.useState(false);
	const handleScroll = () => {
		const offset = window.scrollY;
		if (offset > 200) {
			setScrolled(true);
		}
		else {
			setScrolled(false);
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
	}, []);
	let setSticky = ['active-sticky'];
	if (scrolled) {
		setSticky.push('is-sticky');
	}
	return (
		<Fragment>
			<header id="home" className={`header-style-1 ${DarkVersion ? 'dark-version dark-bg' : 'bg-color-1'}`}>
				<div className="top clearfix">
					<div className={`header-top ptb-50 ${setSticky.join(" ")}`}>
						<Container>
							<Row>
								<Col xs={6} sm={5}>
									<div className="left">
										<div className="logo">
											<Logo logoPath={DarkVersion ? LightLogo : DarkLogo} LogoAlt={DarkVersion ? "Light Logo" : "Dark Logo"}></Logo>
										</div>
									</div>
								</Col>
								<Col xs={6} sm={7}>
									<div className="right">
										<div className="expand-menu-open floatright" onClick={menuToggle}>
											<MdMenu className="icon"></MdMenu>
										</div>
										<nav className="mainmenu onepage-nev floatright">
											<InnerNavItem DarkVersion={DarkVersion} GoBackLink={GoBackLink}></InnerNavItem>
										</nav>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
					<div ref={isRef} className={`mainmenu-expand bg-color-3 ${isActive ? 'slide-from-right' : ''}`}>
						<div className="close-menu" onClick={menuToggle}>
							<MdCancel className="icon"></MdCancel>
						</div>
						<div className="menu-logo">
							<Logo logoPath={require("../../assets/img/dark-logo.png").default} LogoAlt="Dark Logo"></Logo>
						</div>
						<nav className="mainmenu-box onepage-nev">
							<InnerNavItem DarkVersion={DarkVersion} GoBackLink={GoBackLink}></InnerNavItem>
						</nav>
					</div>
				</div>
			</header>
		</Fragment>
	);
}
export default HeaderTwo;



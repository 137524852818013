import React, { Fragment } from 'react';
import { MdLink } from 'react-icons/md';

const PortfolioItem = ({ ItemDataPass }) => {
    return (
        <Fragment>
            <div className="single-portfolio overlay text-center">
                <img src={require('../../assets/img/' + ItemDataPass.Thumb).default} alt={ItemDataPass.Title} loading="lazy" />
                <div className="zoom-icon">
                    <a href={ItemDataPass.workLink} target="_blank" rel="noopener noreferrer">
                        <MdLink></MdLink>
                    </a>
                </div>
                <div className="project-title text-left">
                    <h4 className="font-20 montserrat weight-regular capitalize no-margin">{ItemDataPass.Title}</h4>
                    <p className=" 'Montserrat', sans-serif">
                        <span>{ItemDataPass.SubTitle}</span>

                        {/* {
                            ItemDataPass.categories.slice(0, 1).map((SingleCat, index) =>{
                                return(
                                    <span key={index}>{SingleCat}</span>
                                );
                            })
                        } */}
                    </p>
                </div>
            </div>
        </Fragment>
    );
}
export default PortfolioItem;
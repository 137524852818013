import React,{Fragment} from 'react';
import useMasonry from "../../hooks/use-masonry";
import Filter from "./Filter";
import Data from '../../data/portfolio.json';
import { slugify } from "../../uties";
import PortfolioItem from './PortfolioItem';

const PortfolioGrid = ({ DarkVersion }) => {

    // Isotope Categories list JS
    const { categories } = useMasonry(Data, ".portfolio-grid", ".grid-item", ".filter-button", ".filter-button li")

    return (
        <Fragment>
                <div className="all-portfolio">
                    <div className="portfolio-menu text-center">
                    <Filter categories={categories} />
                    </div>
                </div>
                <div className="portfolio-grid">
                    {
                        Data && Data.map( portfolioData => {
                            return(
                                <div key={portfolioData.id} className={`grid-item percent-50 ${portfolioData.categories.map(cat => slugify(cat)).join(" ")}`}>
                                    <PortfolioItem ItemDataPass={portfolioData} DarkVersion={DarkVersion}></PortfolioItem>
                                </div>
                            );
                        })
                    }
                </div>
        </Fragment>
    )
}

export default PortfolioGrid;

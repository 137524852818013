import React,{Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import SocialMedia from '../helper/SocialMedia';

const Footer = ({ DarkVersion })=>{
    return(
        <Fragment>
			<footer className={`footer ptb-20 ${DarkVersion ? 'dark-version dark-bg' : 'bg-color-1'}`}>
			<Container>
				<Row>
					<Col>
						<div className="left floatleft">
							<p>Copyright &copy; {new Date().getFullYear()} <a href="https://dottheme.com" rel="noreferrer" target="_blank">DotTheme</a>.</p>
						</div>
						<div className="right social-link floatright">
							<SocialMedia></SocialMedia>
						</div>
					</Col>
				</Row>
			</Container>
		</footer>
        </Fragment>
    );
}
export default Footer;
import React, { Fragment, useState } from 'react';
import {Col, Form, Button} from 'react-bootstrap';

const ContactForm = () => {

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    return (
        <Fragment>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className="custom-input" action="https://getform.io/f/5631dfab-ef61-491f-9e2d-cf9ecbe339a5" method="POST">
                <Form.Row className="name-email">
                    <Form.Group as={Col}>
                        <Form.Control type="text" name="name" placeholder="Your Name" required />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Control type="email" name="email" placeholder="Your Email" required />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="mb-0">
                        <Form.Control className="mt-30" name="message" placeholder="Your Message" as="textarea" rows={6} required/>
                        <Button className="btn montserrat weight-regular uppercase mt-30" variant="dark" type="submit">Send Message</Button>
                    </Form.Group>
                </Form.Row>
            </Form>
        </Fragment>
    );
}
export default ContactForm;
import React,{Fragment} from 'react';
import { Link } from "react-scroll";
import {Container, Row, Col} from 'react-bootstrap';
import {HeroOneData, HeroTwoData} from '../data/hero.json';

const Hero = ({ DarkVersion })=>{

    const lightDark = DarkVersion ? HeroTwoData : HeroOneData
    return(
        <Fragment>
            <div className={`hero-area pt-5 ${DarkVersion ? 'dark-version dark-bg' : 'bg-color-1'}`}>
                <Container> 
                    {
                        lightDark.map((HeroContent)=>{
                            return(
                                <Row key={HeroContent.ID} className="relative">
                                    <Col sm={6} className="static">
                                        <div className="hero-text percent-50">
                                            <h5 className="mb-11">{HeroContent.SubTitle}</h5>
                                            <h1 className="mb-30">{HeroContent.Title}</h1>
                                            <p className="font-16 line-height-28">
                                                {HeroContent.Desc}
                                            </p>
                                            <div className="hero-button smooth-scroll mt-40">
                                                <Link className="btn lg-btn btn-dark"
                                                    to='portfolio'
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={500}
                                                >
                                                    {HeroContent.BtnText}
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="hero-img text-right">
                                            <img src={require('../assets/img/slider/' + HeroContent.HeroImg).default} alt={HeroContent.SubTitle} width="555" height="530" />
                                        </div>
                                    </Col>
                                </Row> 
                            );
                        })
                    }
                </Container>
            </div>
        </Fragment>
    );
}
export default Hero;

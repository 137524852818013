import React,{Fragment} from 'react';
import Data from '../../data/timeline.json';
import { MdCheckCircle } from 'react-icons/md';

const Timeline=()=>{
    return(
        <Fragment>
            {
                Data.map(TimelineData=>{
                    return(
                        <div key={TimelineData.id} className={`single-experi w-50 ${TimelineData.TimelineClass?'mb-50': ''}`}>
                            <div className="left-text floatleft relative">
                                <div className={TimelineData.TimelineClass? 'dashed-line': ''}></div>
                                <h4 className="mb-0 montserrat weight-medium capitalize">{TimelineData.CompanyName}</h4>
                                <p className="montserrat weight-medium">{TimelineData.Duration}</p>
                            </div>
                            <div className="right-text">
                                <MdCheckCircle></MdCheckCircle>
                                <h3 className="capitalize font-20">{TimelineData.Title}</h3>
                                <p>{TimelineData.Desc}</p>
                            </div>
                        </div>
                    );
                })
            }
        </Fragment>
    );
}
export default Timeline;
import React,{Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {MdPhone, MdEmail, MdMap} from 'react-icons/md';
import SectionTitle from '../helper/SectionTitle';
import InfoBox from './InfoBox';
import ContactForm from './ContactForm';

const Contact = ({ DarkVersion })=>{
    return(
        <Fragment>
            <section id="contact" className={`contact-area section-padding ${DarkVersion ? 'dark-version bg-color-3' : 'light-bg'}`}>
                <Container>
                    <Row>
                        <Col sm={6} md={4}>
                            <div className="left">
                                <SectionTitle 
                                    title="Get In Touch" 
                                    desc="If you have any question or you're ready to work with me, I'm always here for you"
                                    alignLeft={true}>
                                </SectionTitle>

                                <ul className="contact-text clearfix">
                                    <InfoBox
                                        infoTitle="Phone"
                                        infoText="+880 1640895742"
                                        infoLink="tel:+8801640895742"
                                        infoIcon={<MdPhone/>}>
                                    </InfoBox>
                                    <InfoBox
                                        infoTitle="Email"
                                        infoText="hello.rakibhossain@email.com"
                                        infoLink="mailto:hello.rakibhossain@email.com"
                                        infoIcon={<MdEmail/>}>
                                        link='tel:'
                                    </InfoBox>
                                    <InfoBox
                                        infoTitle="Location"
                                        infoText="Dhaka, Bangladesh"
                                        infoLink="#"
                                        infoIcon={<MdMap/>}>
                                    </InfoBox>
                                </ul>
                            </div>
                        </Col>
                        <Col sm={6} md={8}>
                            <div className="right">
                                <div className="contact-form pt-40">
                                    <ContactForm></ContactForm>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}
export default Contact;
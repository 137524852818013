import React,{Fragment} from 'react';
import Header from '../components/header';
import Footer from '../components/footer/Footer';
import ScrollToTop from '../components/ScrollToTop';

const LayoutOne = ({ DarkVersion, children })=>{
    return(
        <Fragment>
            <Header DarkVersion={DarkVersion}></Header>
            {children}
            <Footer DarkVersion={DarkVersion}></Footer>
            <ScrollToTop></ScrollToTop>
        </Fragment>
    );
}
export default LayoutOne;
import React, { Fragment } from 'react';
import { LayoutOne } from '../../layout';
import Hero from '../../components/Hero';
import AboutMe from '../../components/aboutMe';
import Experience from '../../components/experience';
import Portfolio from '../../components/portfolio';
import Services from '../../components/services';
import Testimonail from '../../components/testimonial';
import BlogPost from '../../components/blog';
import Contact from '../../components/contact';


const HomeLight = () => {
    return (
        <Fragment>
            <LayoutOne DarkVersion={true}>
                <Hero DarkVersion={true}></Hero>
                <AboutMe DarkVersion={true}></AboutMe>
                <Experience DarkVersion={true}></Experience>
                <Portfolio DarkVersion={true}></Portfolio>
                <Services DarkVersion={true}></Services>
                <Testimonail DarkVersion={true}></Testimonail>
                <BlogPost DarkVersion={true}></BlogPost>
                <Contact DarkVersion={true}></Contact>
            </LayoutOne>
        </Fragment>
    );
}
export default HomeLight;

import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import Data from '../../data/nav.json';

const InnerNavItem = ({ GoBackLink }) => {
    return (
        <Fragment>
            <ul>
                {Data.map(item => {
                    return (
                        <li key={item.id}>
                            <Link to={`${GoBackLink}`} >{item.navTitle}</Link>
                        </li>
                    );
                })}
            </ul>
        </Fragment>
    );
}
export default InnerNavItem;





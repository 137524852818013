import React from "react";
import { slugify } from "../../uties";

const Filter = ({ categories }) => {
    return (
        <ul className="filter-button clearfix mb-50">
            <li data-filter="*" className="active">All Projects</li>
            {categories.map((cat, idx) => <li key={idx} data-filter={`.${slugify(cat)}`}>{cat}</li>)}
        </ul>
    )
}
export default Filter;

import React, { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from '../helper/SectionTitle';
import Service from './Service';

const Services = ({ DarkVersion }) => {
    return (
        <Fragment>
            <section id="service" className={`service-area section-padding ${DarkVersion ? 'dark-version dark-bg' : 'bg-color-1'}`}>
                <Container className="container">
                    <Row>
                        <Col>
                            <SectionTitle title="My Services"></SectionTitle>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Service></Service>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}
export default Services;
import React,{Fragment} from 'react';

const InfoBox = ({ infoTitle, infoText, infoIcon, infoLink})=>{
    return(
        <Fragment>
            <li>
                <i>{infoIcon}</i>
                <h4 className="montserrat weight-regular no-margin capitalize">{infoTitle}</h4>
                <span><a className="montserrat weight-regular" href={infoLink}>{infoText}</a></span>
            </li>
        </Fragment>
    );
}
export default InfoBox;
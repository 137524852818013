import React,{Fragment} from 'react';
import {Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { MdAdd, MdAccountCircle, MdPermContactCalendar } from 'react-icons/md';
import Data from '../../data/blogPost.json';

const Post = ({ DarkVersion }) => {
    return(
        <Fragment>
            {
                Data.slice(0, 3).map( PostData => {
                    return(
                        <Col key={PostData.id} sm={4} className="mobile-mb-30">
                            <div className="single-blog clearfix">
                                <Link to={`${DarkVersion ? '/blog-post/' : '/blog-post-light/'}${PostData.id}`} >
                                    <img src={PostData.Thumbnail} alt={PostData.Title} loading="lazy" width="360" height="215"/>
                                </Link>
                                <div className="date-comment plr-20 ptb-20 clearfix">
                                    <div className="left floatleft">
                                        <h6 className="capitalize montserrat no-margin weight-regular">
                                            <MdPermContactCalendar></MdPermContactCalendar>
                                            {PostData.Date}
                                        </h6>
                                    </div>
                                    <div className="right floatright">
                                        <h6 className="capitalize montserrat no-margin weight-regular">
                                            <MdAccountCircle></MdAccountCircle>
                                            <span dangerouslySetInnerHTML={{ __html: PostData.Author }} />
                                        </h6>
                                    </div>
                                </div>
                                <div className="blog-title clearfix">
                                    <div className="left floatleft">
                                        <Link to={`${DarkVersion ? '/blog-post/' : '/blog-post-light/'}${PostData.id}`} >
                                             <h4 className="capitalize montserrat no-margin weight-regular">{PostData.Title}</h4>
                                        </Link>
                                    </div>
                                    <div className="right floatright">
                                        <Link to={`${DarkVersion ? '/blog-post/' : '/blog-post-light/'}${PostData.id}`} >
                                            <MdAdd></MdAdd>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    );
                })
            }
        </Fragment>
    );
}
export default Post;

import React, { useRef, useState, useEffect } from 'react';
import { Fragment } from 'react';
import SkillBar from './SkillBar';

const MimSkillBar = ({ threshold }) => {

    const ref = useRef(null);
    const [isChildVisible, setIsChildVisible] = useState(false);
    
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsChildVisible(true);
                }
                if (entry.intersectionRatio > 0) {
                    
                }
                if (entry.intersectionRatio <= 0) {
                    
                }
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: threshold,
            }
        );
        if (ref && ref.current) {
            observer.observe(ref.current);
        }
    }, [isChildVisible, threshold]);
    return (
        <Fragment>
            <div ref={ref}>
                {isChildVisible ? (
                    <>
                        <SkillBar Title="HTML, CSS" Percentage="90"></SkillBar>
                        <SkillBar Title="WordPress" Percentage="85"></SkillBar>
                        <SkillBar Title="React Js" Percentage="60"></SkillBar>
                    </>
                ) : null}
            </div>
        </Fragment>
    );
}
export default MimSkillBar;
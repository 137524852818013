import React,{Fragment} from 'react';
import {LayoutOne} from '../../layout';
import Hero from '../../components/Hero';
import AboutMe from '../../components/aboutMe';
import Experience from '../../components/experience';
import Portfolio from '../../components/portfolio';
import Services from '../../components/services';
import Testimonail from '../../components/testimonial';
import BlogPost from '../../components/blog';
import Contact from '../../components/contact';


const HomeLight = () => {
    return(
        <Fragment>
            <LayoutOne DarkVersion={false}>
                <Hero DarkVersion={false}></Hero>
                <AboutMe DarkVersion={false}></AboutMe>
                <Experience DarkVersion={false}></Experience>
                <Portfolio DarkVersion={false}></Portfolio>
                <Services DarkVersion={false}></Services>
                <Testimonail DarkVersion={false}></Testimonail>
                <BlogPost DarkVersion={false}></BlogPost>
                <Contact DarkVersion={false}></Contact>
            </LayoutOne>
        </Fragment>
    );
}
export default HomeLight;

import React,{Fragment} from 'react';

const SinglePost = ({ PostDataPass }) =>{
    return(
        <Fragment>
            <div className="single-post">
                <div className="img mb-60">
                    <img src={PostDataPass.Thumbnail} alt={PostDataPass.Title} loading="lazy" width="945" height="560"/>
                </div>
                <div className="text">
                    <h3 className="font-26 montserrat weight-medium capitalize mb-10">{PostDataPass.Title}</h3>
                    <h5 className="capitalize montserrat weight-regular no-margin">Date : <span className="montserrat-600">{PostDataPass.Date}</span> By <span className="montserrat-600" dangerouslySetInnerHTML={{ __html: PostDataPass.Author }} /></h5>
                    
                    <div className="ptb-40 mb-60" dangerouslySetInnerHTML={{ __html: PostDataPass.Content.join(' ') }} />
                </div>
            </div>
        </Fragment>
    );
}
export default SinglePost;
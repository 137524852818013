import React,{Fragment} from'react';
import {Container, Row, Col} from 'react-bootstrap';
import SectionTitle from '../helper/SectionTitle';
import MyCV from './MyCV';
import MimSkillBar from '../MimSkillBar';

const AboutMe = ({ DarkVersion , DarkText })=>{
    return(
        <Fragment>
            {/* <!-- About Section Start --> */}
            <section id="about" className={`about-area section-padding ${DarkVersion ? 'dark-version bg-color-3' : 'bg-light'}`}>
                <Container>
                    <Row>
                        <Col>
                            <SectionTitle DarkText={DarkText} title="About Me"></SectionTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <MyCV></MyCV>
                        </Col>
                        <Col sm={6} >
                            <div className="right skill-progress mx-auto">
                                <MimSkillBar></MimSkillBar>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* <!-- About Section End --> */}
        </Fragment>
    );
}
export default AboutMe;
import React,{Fragment} from 'react';

const SectionTitle = ({ title, desc, alignLeft})=>{
    return(
        <Fragment>
            <div className={`section-title ${alignLeft ? 'text-left mb-40' : 'text-center mb-60'}`}>
                <h2 className="mb-20">{title}</h2>
                <div className="horizontal-line">
                    <div className="top"></div>
                    <div className="bottom"></div>
                </div>
                <p className="mt-3">{desc}</p>
            </div>
        </Fragment>
    );
}
export default SectionTitle;
import React from 'react';
import { Link } from 'react-router-dom';

const Logo = ({logoPath, LogoAlt}) => {
 return(
     <Link to="/">
         <img src={logoPath} alt={LogoAlt} />
     </Link>
 );
}
export default Logo;
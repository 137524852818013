import React, { Fragment } from 'react';
import { InnerLayout } from '../../layout';
import BlogDetails from '../../components/blog/BlogDetails';
import Data from '../../data/blogPost.json';


const SinglePostDark = ({
    match: {
        params: { id }
    }
}) => {
    const blogId = parseInt(id, 10);
    const passedData = Data.filter((blog) => blog.id === blogId);

    return (
        <Fragment>
            <InnerLayout DarkVersion={true} GoBackLink="/">
                <BlogDetails DarkVersion={true} PostData={passedData[0]}></BlogDetails>
            </InnerLayout>
        </Fragment>
    );
}
export default SinglePostDark;
